import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Page = () => {
  const siteTitle = 'Psychoterapia Mosty - Formularz wysłany'
  const siteDescription = ''

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
        <meta name="robots" content="noindex, follow" />
      </Helmet>

      <div id="main">
        {/* <section id="one">
          <header className="major">
            <h2>O nas</h2>
          </header>
        </section> */}

        <section>
          <h2>Dziękujemy za wysłanie formularza</h2>
        </section>
      </div>
    </Layout>
  )
}

export default Page
